<template>
  <div
    v-if="false"
    class="floating-notification notification">
    <button
      class="delete"
      @click="close" />
    <table class="table is-striped is-narrow floating-table">
      <thead>
        <tr v-if="title">
          <th colspan="4">{{ title }}</th>
        </tr>
      </thead>
      <tfoot>
        <tr v-if="totals != null">
          <td class="has-text-weight-bold">Totals</td>
          <td class="has-text-weight-bold has-text-right">{{ totals | formatCurrency($userInfo.locale) }}</td>
        </tr>
        <tr v-if="totalExGst != null">
          <td class="has-text-weight-bold">Grand Total</td>
          <td
            colspan="4"
            class="has-text-weight-bold has-text-right">{{ totalExGst | formatCurrency($userInfo.locale) }}</td>
        </tr>
      </tfoot>
      <tbody>
        <tr v-if="rrTotal != null || cdTotal != null">
          <td v-if="rrTotal != null">R&amp;R</td>
          <td
            v-if="rrTotal != null"
            class="has-text-right">{{ rrTotal | formatCurrency($userInfo.locale) }}</td>
          <td v-if="mechTotal != null">MECH</td>
          <td
            v-if="mechTotal != null"
            class="has-text-right">{{ mechTotal | formatCurrency($userInfo.locale) }}</td>
        </tr>
        <tr v-if="repairTotal != null || cdTotal != null">
          <td v-if="repairTotal != null">Repair</td>
          <td
            v-if="repairTotal != null"
            class="has-text-right">{{ repairTotal | formatCurrency($userInfo.locale) }}</td>
          <td v-if="cdTotal != null">CD</td>
          <td
            v-if="cdTotal != null"
            class="has-text-right">{{ cdTotal | formatCurrency($userInfo.locale) }}</td>
        </tr>
        <tr v-if="rwaTotal != null || cdTotal != null">
          <td v-if="rwaTotal != null">RWA</td>
          <td
            v-if="rwaTotal != null"
            class="has-text-right">{{ rwaTotal | formatCurrency($userInfo.locale) }}</td>
          <td v-if="crushTotal != null">CRUSH</td>
          <td
            v-if="crushTotal != null"
            class="has-text-right">{{ crushTotal | formatCurrency($userInfo.locale) }}</td>
        </tr>
        <tr v-if="paintTotal != null || cdTotal != null">
          <td v-if="paintTotal != null">Paint</td>
          <td
            v-if="paintTotal != null"
            class="has-text-right">{{ paintTotal | formatCurrency($userInfo.locale) }}</td>
          <td v-if="fgTotal != null">FIBER</td>
          <td
            v-if="fgTotal != null"
            class="has-text-right">{{ fgTotal | formatCurrency($userInfo.locale) }}</td>
        </tr>
        <tr v-if="partTotal != null || cdTotal != null">
          <td v-if="partTotal != null">Part</td>
          <td
            v-if="partTotal != null"
            class="has-text-right">{{ partTotal | formatCurrency($userInfo.locale) }}</td>
          <td v-if="opgTotal != null">OPG</td>
          <td
            v-if="opgTotal != null"
            class="has-text-right">{{ opgTotal | formatCurrency($userInfo.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'TotalsFloatingWidget',
  mixins: [NumberFiltersMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: true
    },
    rrTotal: {
      type: Number,
      default: null
    },
    repairTotal: {
      type: Number,
      default: null
    },
    rwaTotal: {
      type: Number,
      default: null
    },
    paintTotal: {
      type: Number,
      default: null
    },
    cdTotal: {
      type: Number,
      default: null
    },
    crushTotal: {
      type: Number,
      default: null
    },
    mechTotal: {
      type: Number,
      default: null
    },
    fgTotal: {
      type: Number,
      default: null
    },
    partTotal: {
      type: Number,
      default: null
    },
    opgTotal: {
      type: Number,
      default: null
    },
    totals: {
      type: Number,
      default: null
    },
    totalExGst: {
      type: Number,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('update:active', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.floating-notification {
  animation-name: bounceIn;
  animation-duration: 0.8s;
  animation-delay: 0s;
  // animation-timing-function: ease-out;
  // animation-direction: alternate;
  // animation-iteration-count: infinite;
  // animation-fill-mode: none forwards;
  // animation-play-state: running;
  opacity: 0.8;
  left: 0.6em;
  bottom: 0.6em;
  display: block;
  position: fixed;
  z-index: auto;
}
.floating-table {
  background: rgba(0, 0, 0, 0);
}
</style>

<template>
  <div>
    <svg v-show="diff === 0"
      :class="`fqi-svg-color1-${getColour('success')}`"
      viewBox="0 0 32 32"
      style="margin-bottom: 1px">
      <!-- <use xlink:href="#fqi-svg-tick-circle" /> -->
      <path fill="#231f20"
        style="fill: var(--color1, #231f20)"
        d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
      <path fill="#fff"
        style="fill: var(--color2, #fff)"
        d="M13.6 22.72l-5.44-5.6 1.44-1.6 3.68 3.68 8.8-9.92 1.76 1.44z" />
    </svg>
    <svg v-show="diff === null"
      :class="`fqi-svg-color1-${getColour('warning')}`"
      viewBox="0 0 32 32"
      style="margin-bottom: 1px">
      <!-- <use xlink:href="#fqi-svg-pending-auth" /> -->
      <path d="M2.701 30.896h26.758c0.276 0 0.552 0.276 0.552 0.552s-0.276 0.552-0.552 0.552h-26.758c-0.276 0-0.552-0.276-0.552-0.552s0.276-0.552 0.552-0.552z" />
      <path d="M2.701 0h26.758c0.276 0 0.552 0.276 0.552 0.552s-0.276 0.552-0.552 0.552h-26.758c-0.276 0-0.552-0.276-0.552-0.552s0.276-0.552 0.552-0.552z" />
      <path d="M4.908 27.31v4.69h22.069v-4.69c0-1.379-0.276-2.483-1.103-3.586l-5.241-7.448 5.241-7.448c0.828-1.103 1.103-2.207 1.103-3.586v-4.69h-22.069v4.69c0 1.379 0.276 2.483 1.103 3.586l5.241 7.448-5.241 7.448c-0.552 1.103-1.103 2.207-1.103 3.586zM10.701 6.069c-0.276-0.276-0.276-0.828-0.276-1.379v-0.828h11.586v0.828c0 0.552-0.276 0.828-0.276 1.379l-4.414 6.345c-0.276 0.552-1.103 0.552-1.655 0.276l-0.276-0.276z" />
    </svg>
    <svg v-show="diff !== 0 && diff !== null"
      :class="`fqi-svg-fill-${iconColour}`"
      style="margin-bottom: 1px"
      title="Authorisation Info"
      data-html="true"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="12"
      height="12"
      :transform="`rotate(${ diff > 0 ? 0 : 180 })`"
      viewBox="0 0 24 24">
      <path d="M13,18H11V10L7.5,13.5L6.08,12.08L12,6.16L17.92,12.08L16.5,13.5L13,10V18M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AuthorisedIcon',
  components: {},
  mixins: [],
  props: {
    diff: {
      type: Number,
      default: null
    },
    useThemeColour: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {}
  },
  computed: {
    iconColour() {
      if (this.diff > 0) {
        return this.getColour('success')
      } else if (this.diff < 0) {
        return this.getColour('danger')
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    getColour(colour) {
      const result = `${colour}-light`
      return result
    }
  },
  beforeRouteEnter(to, from, next) {},
  beforeRouteLeave(to, from, next) {}
}
</script>

<style lang="scss" scoped>
</style>
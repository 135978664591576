<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <!-- <slot name="text-title"></slot> -->
        <span>Copy Quote</span>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Copy to</label>
        <div class="pretty p-default p-round p-bigger p-smooth">
          <input type="radio"
            id="newQuote"
            :value="0"
            v-model="copyQuoteType">
          <div class="state p-primary-o">
            <label>New Quote</label>
          </div>
        </div>
        <div class="pretty p-default p-round p-bigger p-smooth">
          <input type="radio"
            id="existingQuote"
            :value="1"
            v-model="copyQuoteType">
          <div class="state p-primary-o">
            <label>Existing Quote</label>
          </div>
        </div>
        <!-- <input class="is-checkradio"
          id="newQuote"
          type="radio"
          name="newQuote"
          :value="0"
          v-model="copyQuoteType">
        <label for="newQuote">New Quote</label>
        <input class="is-checkradio"
          id="existingQuote"
          type="radio"
          name="existingQuote"
          :value="1"
          v-model="copyQuoteType">
        <label for="existingQuote">Existing Quote</label> -->
      </div>
      <div v-if="copyQuoteType === copyQuoteTypes.Existing"
        class="field">
        <div class="is-divider" />
        <div class="field">
          <input v-if="copyQuoteType === copyQuoteTypes.Existing"
            v-model="quoteNo"
            @input="searchQuote"
            class="input"
            type="text"
            placeholder="Quote No."
            v-focus-inserted>
          <span v-if="validQuote && copyQuoteType === copyQuoteTypes.Existing"
            class="help is-success">Valid Quote No.</span>
          <span v-else-if="quoteNo && copyQuoteType === copyQuoteTypes.Existing"
            class="help is-danger">Invalid Quote No.</span>
        </div>
        <div class="field">
          <div class="control">
            <div class="pretty p-icon p-curve p-smooth p-bigger">
              <input id="chk-overwrite-quote"
                type="checkbox"
                v-model="isOverwrite">
              <div class="state p-primary">
                <i class="icon mdi mdi-check" />
                <label>Overwrite</label>
              </div>
            </div>
            <!-- <input class="is-checkradio"
              id="chk-overwrite-quote"
              name="chk-overwrite-quote"
              type="checkbox"
              v-model="isOverwrite">
            <label for="chk-overwrite-quote">Overwrite</label> -->
          </div>
        </div>
      </div>
      <div v-if="imageIds.length > 0"
        class="field">
        <div class="control">
          <div class="pretty p-icon p-curve p-smooth p-bigger">
            <input id="chk-copyImages"
              type="checkbox"
              v-model="isCopyImages">
            <div class="state p-primary">
              <i class="icon mdi mdi-check" />
              <label>Copy Images</label>
            </div>
          </div>
          <!-- <input class="is-checkradio"
            id="chk-copyImages"
            name="chk-copyImages"
            type="checkbox"
            v-model="isCopyImages">
          <label for="chk-copyImages">Copy Images</label> -->
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="copy()"
        data-tooltip="Start copy"
        :disabled="copyDisabled || (!validQuote && copyQuoteType === copyQuoteTypes.Existing)">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-content-copy" />
        </span>
        <span>Copy</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { FocusInserted } from '@/components/directives'
import { guidValidator } from '@/components/validators'
import QuoteService from '../QuoteService'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'

const CopyQuoteTypes = {
  New: 0,
  Existing: 1
}

export default {
  name: 'CopyQuoteModal',
  components: {
    BaseModalEx
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-content-copy'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    imageIds: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isActive: false,
      innerValue: null,
      copyQuoteType: CopyQuoteTypes.New,
      quoteNo: null,
      quoteId: Guid.empty(),
      isCopyImages: false,
      isOverwrite: false
    }
  },
  validations: {
    quoteId: {
      validGuid: guidValidator
    }
  },
  computed: {
    copyQuoteTypes() {
      return CopyQuoteTypes
    },
    validQuote() {
      return this.$v.quoteId.$dirty && !this.$v.quoteId.$error && this.quoteNo !== this.innerValue.quoteNo
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    copy() {
      this.$emit('update:active', false)
      this.$emit('copy', this.quoteId, this.isCopyImages, this.isOverwrite)
    },
    cancel() {
      this.$emit('update:active', false)
      this.$emit('cancel')
    },
    searchQuote: _debounce(async function () {
      this.$v.quoteId.$touch()
      if (this.quoteNo) {
        this.quoteId = await QuoteService.getQuoteIdByNo(this.quoteNo)
      } else {
        this.quoteId = Guid.empty()
      }
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.is-divider {
  margin: 1.2em 0;
}
</style>

<template>
  <div>
    <tippy v-if="isMounted && isInnerActivated && hasAssessments"
      arrow
      theme="light"
      placement="top">
      <div class="card tippy-no-padding">
        <div class="card-header p-1">
          <span>Assessment History</span>
        </div>
        <div class="card-body p-1">
          <table>
            <thead>
              <tr>
                <td class="" />
                <td>
                  <span class="badge badge-sm"
                    :class="[ `bg-${getColour('light')}`, `text-${getColour('dark')}` ]">Quoted</span>
                </td>
                <td />
                <td><span class="badge badge-sm"
                  :class="[ `bg-${getColour('light')}`, `text-${getColour('dark')}` ]">Authorised</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="assessment in itemAssessments"
                :key="getSupplementaryNo(assessment)">
                <td class="label-xxs"
                  :class="[ getAssessmentDiff(assessment) >= 0 ? `text-${getColour('success')}` : `text-${getColour('danger')}` ]">
                  <span v-show="getSupplementaryNo(assessment) === 0">Original</span>
                  <span v-show="getSupplementaryNo(assessment) > 0">Supp #{{ assessment[0].subQuoteNo }}</span>
                </td>
                <td v-if="assessment[0]">

                  <div v-if="assessment[0].isQuoteItemDeleted">
                    <span class="badge badge-sm"
                      :class="[`bg-${getColour('danger')}`, `text-${getColour('invert')}`]">
                      Deleted
                    </span>
                  </div>
                  <div v-else-if="assessment[0].reportOnly">
                    <span class="badge badge-sm"
                      :class="[`bg-${getColour('danger')}`, `text-${getColour('invert')}`]">
                      Report Only
                    </span>
                  </div>
                  <div v-else>
                    <div>
                      <span v-if="isItemlabour"
                        class="badge badge-sm"
                        :class="[ `bg-${getColour('light')}`, `text-${getColour('dark')}` ]">{{ $filters.formatNumber(assessment[0].hourValue) }}h
                      </span>
                      <span class="badge badge-sm"
                        :class="[ `bg-${getColour('light')}`, `text-${getColour('dark')}` ]">
                        {{ $filters.formatCurrency(totalValue(assessment[0]), $userInfo.locale) }}
                      </span>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <span />
                </td>
                <td>
                  <authorised-icon class="authorised-icon"
                    v-show="hasAssessments"
                    :diff="getAssessmentDiff(assessment)" />
                </td>
                <td v-if="assessment.length > 1">

                  <div v-if="assessment[1].isQuoteItemDeleted">
                    <span class="badge badge-sm"
                      :class="[`bg-${getColour('danger')}`, `text-${getColour('invert')}`]">
                      Deleted
                    </span>
                  </div>
                  <div v-else-if="assessment[1].reportOnly">
                    <span class="badge badge-sm"
                      :class="[`bg-${getColour('danger')}`, `text-${getColour('invert')}`]">
                      Report Only
                    </span>
                  </div>
                  <div v-else>
                    <div>
                      <span v-if="isItemlabour"
                        class="badge badge-sm"
                        :class="[ `bg-${getColour('light')}`, `text-${getColour('dark')}` ]">{{ $filters.formatNumber(assessment[1].hourValue) }}h
                      </span>
                      <span class="badge badge-sm"
                        :class="[ `bg-${getColour('light')}`, `text-${getColour('dark')}` ]">
                        {{ $filters.formatCurrency(totalValue(assessment[1]), $userInfo.locale) }}
                      </span>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <span class="badge badge-sm"
                    :class="`bg-${getColour('light')}`">{{ 'Pending' }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </tippy>
    <div class="supplement-container">
      <authorised-icon class="authorised-icon"
        v-if="hasAssessments"
        :diff="authorisedValueDiff" />
      <svg :class="`fqi-svg-fill-${supplementaryIconColour}`"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        :width="`${width}`"
        :height="`${height}`"
        fill="text-primary"
        xml:space="preserve">
        <g>
          <g>
            <path d="M511.957,185.214L512,15.045l-67.587,67.587l-7.574-7.574c-48.332-48.332-112.593-74.95-180.946-74.95
              C114.792,0.107,0,114.901,0,256s114.792,255.893,255.893,255.893S511.785,397.099,511.785,256h-49.528
              c0,113.79-92.575,206.365-206.365,206.365S49.528,369.79,49.528,256S142.103,49.635,255.893,49.635
              c55.124,0,106.947,21.467,145.925,60.445l7.574,7.574l-67.58,67.58L511.957,185.214z" />
          </g>
        </g>

        <text x="50%"
          y="50%"
          text-anchor="middle"
          font-size="300"
          dy=".35em">{{ supplementaryNo }}</text>
      </svg>
    </div>
  </div>
</template>

<script>
import storeMixin from '../storeMixin'
import AuthorisedIcon from './AuthorisedIcon'
import { ItemCategoryCodeTypes } from '@/enums'
import { QuoteAssessmentMixin } from '../mixins'

export default {
  name: 'SupplementaryIconOld',
  components: {
    AuthorisedIcon
  },
  mixins: [storeMixin, QuoteAssessmentMixin],
  props: {
    item: {
      type: Object,
      default: null
    },
    itemAssessments: {
      type: Array,
      default: null
    },
    nextAssessmentNumber: {
      type: Number,
      default: null
    },
    quotingMethod: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 18
    },
    colour: {
      type: String,
      default: 'success'
    },
    useThemeColour: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      isMounted: false,
      isInnerActivated: true
    }
  },
  computed: {
    isItemlabour() {
      return ItemCategoryCodeTypes.itemTypes.filter((i) => i.valueCalculationType === 'Labour').some((c) => c.code === this.item.itemType)
    },
    hasAssessments() {
      return this.itemAssessments.length > 0
    },
    lastAssessment() {
      // find the assessment with maximum subQuoteNo in itemAssessments
      if (this.hasAssessments) {
        return this.itemAssessments[this.itemAssessments.length - 1]
      }
      return []
    },
    authorisedValueDiff() {
      //Do not have authorised assessment: return null
      if (!this.lastAssessment || this.lastAssessment.length < 2) return null
      return this.getAssessmentDiff(this.lastAssessment)
    },
    supplementaryNo() {
      if (this.itemChanged(this.item, this.item.lastQuotedAssessment, this.item.lastAuthorisedAssessment, this.quotingMethod)) {
        return this.nextAssessmentNumber
      }
      if (this.lastAssessment[0]) {
        return this.lastAssessment[0].subQuoteNo
      }
      else {
        return this.lastAssessment[1].subQuoteNo
      }
    },
    NumValuesPerAssessmentDetail() {
      //Number of column in the assessment history table for each assessment detail
      if (this.isItemlabour) {
        //Hourly Rate and dollar value
        return 2
      } else {
        //total value
        return 1
      }
    },
    supplementaryIconColour() {
      let color = 'danger'
      if (this.lastAssessment.length === 2) {
        color = 'success'
      }
      if (this.lastAssessment.length === 1) {
        color = 'warning'
      }
      if (this.itemChanged(this.item, this.item.lastQuotedAssessment, this.item.lastAuthorisedAssessment, this.quotingMethod)) {
        color = 'danger'
      }
      return this.getColour(color)
    }
  },
  watch: {},
  created() {},
  updated() {},
  mounted() {
    this.isMounted = true
  },
  activated() {
    this.isInnerActivated = true
  },
  methods: {
    getAssessmentDiff(assessment) {
      if (!assessment) return null
      let requested = assessment[0]
      let authorised = assessment[1]
      if (requested && authorised) {
        return this.totalValue(authorised) - this.totalValue(requested)
      } else if (authorised && !requested) {
        return 0
      } else {
        return null
      }
    },
    getColour(colour) {
      const result = `${colour}-light`
      return result
    },
    totalValue(assessmentDetail) {
      if (assessmentDetail === null) return null
      if (this.isItemlabour) {
        return assessmentDetail.dollarValue
      } else if (ItemCategoryCodeTypes.itemTypes.filter(c => c.category === 'Part').some(c => c.code === this.item.itemType)) {
        return assessmentDetail.itemQuantity * assessmentDetail.markupValue
      } else if (ItemCategoryCodeTypes.itemTypes.filter(c => c.category === 'Misc' || c.category === 'Sublet').some(c => c.code === this.item.itemType)) {
        return assessmentDetail.value
      }
    },
    getSupplementaryNo(assessment) {
      if (assessment === null || assessment.length === 0) return 0
      if (assessment[0]) {
        return assessment[0].subQuoteNo
      }
      else {
        return assessment[1].subQuoteNo
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tippy-no-padding {
  margin: -5px 0px !important;
  line-height: 1;
  white-space: nowrap;
}

.supplement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}
.authorised-icon {
  height: 10px;
  width: 10px;
}
td {
  padding-left: 0.5rem !important;
}
</style>
